<template>
<div>
    <div id="works-box">
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <div class="grid-col"></div>
        <router-link class="work-cell" v-for="(work, i) in $options.works" :key="i" :to="`/works/${i}`">
            <div class="card hover-highlight">
                <img :src="work.cover">
                <p>{{work.title}}</p>
            </div>
        </router-link>
    </div>
    <div id="modal" :class="{'modal-shown': shouldShowModal, 'modal-hidden': !shouldShowModal}">
        <router-view>
        </router-view>
    </div>
</div>
</template>

<script>
import NavBar from '../components/NavBar'
import works from '../assets/data/works'
import Colcade from '../helper/Colcade'

export default {
    name: 'works-page',
    title: 'Works',
    components: {
        // NavBar
    },
    works: works,
    mounted() {
        this.colcade = new Colcade('#works-box', {
            columns: '.grid-col',
            items: '.work-cell'
        })
    },
    computed: {
        shouldShowModal() {
            return this.$route.params.workId!==undefined
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/grid.scss';
@include grid(4, works-box);

// set modal size and backdrop
@import '@/scss/modal';
@include modal-backdrop;

#works-box .work-cell {
    div {
        overflow: hidden;
        margin-bottom: 6vw;
        @media screen and (min-width: $tabletWidth) {
            margin-bottom: 3vw;
        }
        
        img {
            display: block;
            width: 100%;
        }
        p {
            font-size: 0.7em;
            margin: 2vw;

            @media screen and (min-width: $tabletWidth) {
                margin: 1.2vw;
            }
        }
    }
}

</style>