export default [
    {
        title : "bilibili world in 上海",
        cover : require('@/assets/images/works/20200809-1.png'),
        desc : "Bilibili World 2020 in 上海に出演させて頂くことになりました(((o(*ﾟ▽ﾟ*)o)))みんなに会えるのが楽しみおまたせ…！楽しんでもらえるように頑張るから 来てね まってるよ！！",
        link : {
            name : "BiliBili World 2020",
            url : "https://bw.bilibili.com/2020/index.html"
        }
    },

    {
        title : "VUP cafe 2020 in 上海",
        cover : require('@/assets/images/works/20200801-1.png'),
        desc : "猫猫のスイーツ＆グッズ！凄く可愛いでしょ！！ばっちり監修させていただきました(*´ω｀*)グッズは他にもありますので、チェックしてみてね！良かったら皆さん、遊びに来てくださいね！！",
        link : {
            name : "Vup Cafe 2020 in 上海",
            url : "https://t.bilibili.com/418113386938488740"
        }
    },

    {
        title : "FMラジオ とびきり！BUZZ Music",
        cover : require('@/assets/images/works/20200721-1.png'),
        desc : "深夜0am, Mon 7/20 ▶ KAWAII MUSIC presents とびきり！Buzz MusicDJ: #天輝おこめ @a_okome_channelネット中心に活動しているアーティストの音楽をピックアップ、バズっているゲストをお招きする番組です",
        link : {
            name : "天輝おこめ",
            url : "https://twitter.com/a_okome_channel/status/1285218169615876097"
        }
    },

    {
        title : "花園セレナ×ＷＥＢポン！",
        cover : require('@/assets/images/works/20200522-1.png'),
        desc : "花園セレナ×ウェブポン発売記念キャンペーン",
        link : {
            name : "WebPon",
            url : "https://webpon.net/shop/serena/plan/HS"
        }
    },

    {
        title : "『インディV』個人Vインタビュー雑誌",
        cover : require('@/assets/images/works/20200502-1.png'),
        desc : "個人系VTuberインタビュー同人誌のインディV創刊号です。気になる個人系VTuberの皆さんにインタビューを行い、普段の配信では聞くことができない本音や想いを深掘りしちゃいました!表紙の花園セレナさんをはじめ、森永みうさん、高槻りつさん、高峰伊織さん、双葉汐音さんにご参加頂いた、豪華な一冊となっています。是非ご一読ください!",
        link : {
            name : "インディV",
            url : "https://booth.pm/ja/items/2029330"
        }
    },

    {
        title : "エンタス練習会DTM講座×花園セレナ",
        cover : require('@/assets/images/works/20200414-1.png'),
        desc : "エンタスYouTubeチャンネルにて#エンタス練習会配信！初回は講師yuzenによるDJ・DTM講座です！DTMコーナーでは花園セレナ様にいただいたボイスで楽曲を作っていくぞ！セレナ様ありがとうございます！お楽しみに！",
        link : {
            name : "TAKUYA the bringer",
            url : "https://twitter.com/selecta_takuya/status/1249597563331723265"
        }
    },

    {
        title : "ビリビリ動画 リアルイベント「BiliBili World 広州」",
        cover : require('@/assets/images/works/201908-1.png'),
        desc : "中国の大規模サブカルイベント「BILIBILI WORLD 2019 広州」が開催されます。イベントではOveridea所属のVTuberの他、ゲストとしてにじさんじやホロライブなど、多数のVTuberが出演します。",
        link : {
            name : "Bilibili World 広州",
            url : "https://bw.bilibili.com/2019/index.html#/guangzhou/"
        }
    },

    {
        title : "株式会社REALITY Factory リアルイベント「夢ノ花園へようこそ♥」",
        cover : require('@/assets/images/works/201904.jpg'),
        desc : "VTuber花園セレナ&ユメノシオリが初めてのリアルイベントを開催！2人のトークと2対1のお話し会(1人60秒まで)で彩る2人の\"夢ノ花園へようこそ♡\"",
        link : {
            name : "livepocket",
            url : "https://t.livepocket.jp/e/yumenohanazono"
        }
    },

    {
        title : "株式会社プロバキャスト リアルイベント 「Vtu\"Bar\"~Sappro Rog-inn Vtuber~」",
        cover : require('@/assets/images/works/201911.jpg'),
        desc : "第2回北海道Vtuberリアルイベント開催！第1回で見つかった課題点を改善し、より迫力のある映像をお届けするため、この回より巨大スクリーンとプロジェクターを導入。参加数も伸びており、初の3人別枠の開催で、イメージドリンクやフードも非常に好評でした！",
        link : {
            name : "V-Tieup",
            url : "https://v-tieup.net/2020/07/11/vtubarvol-1%e3%80%90%e7%b4%a1%e9%9f%b3%e3%82%8c%e3%81%84%e6%a7%98%e3%83%bb%e7%99%bd%e9%9f%b3%e3%81%93%e3%82%86%e3%81%8d%e6%a7%98%e9%bb%92%e9%9f%b3%e3%82%88%e3%82%8b%e6%a7%98%e3%80%91/"
        }
    },

    {
        title : "ビリビリ動画 リアルイベント「BiliBili World 成都」",
        cover : require('@/assets/images/works/201912-1.jpg'),
        desc : "ビリビリワールド2019成都花園serenaも出演することになりました！とてもうれしく思います！また中国のご主人様に会えるなんて(´;ω;｀)",
        link : {
            name : "Bilibili World 成都",
            url : "https://bw.bilibili.com/2019/index.html#/chengdu/"
        }
    },

    {
        title : "ビリビリｘ猫耳FM ラジオドラマ 「非科学定制」主役：美奈子",
        cover : require('@/assets/images/works/202001-1.png'),
        desc : "「非科学定制」というビリビリ公式ボイスドラマ「美奈子」役を演じさせていただきました！",
        link : {
            name : "非科学定制",
            url : "https://www.bilibili.com/bangumi/media/md28226990/"
        }
    },

    {
        title : "Overidea オンラインイベント 「歌え！国境なき日中新年企画！」2019",
        cover : require('@/assets/images/works/202002.jpg'),
        desc : "本放送では総勢３３名にもなるVTuberが世界中にいる皆さんにワンコーラスでメドレー式に歌をお届けします。歌の後には実際に登場して頂きトークをしますが、当日ご多忙な参加者からは素敵なメッセージ動画が届きます。中国と日本、新しいVTuberを知ってみる良い機会ですね！なお、本企画はビリビリ動画とOverideaによる共同主催となります。",
        link : {
            name : "配信録画",
            url : "https://www.youtube.com/watch?v=h-Qh0-t4JqI"
        }
    },

    {
        title : "Overidea オンラインイベント 「歌え！国境なき世界新年企画！」2020",
        cover : require('@/assets/images/works/202002.jpg'),
        desc : "本放送では総勢６０名以上にもなるVTuberが世界中にいる皆さんにワンコーラスでメドレー式に歌をお届けします。歌の後にはVTR出演もしくは生出演で交流！国境を超えたものがここにはある。そう感じて頂ければ幸いです。これを期に、様々なVTuberさんと出会ってみてはいかがでしょう？なお、本企画はビリビリ動画とOverideaによる共同主催となります。",
        link : {
            name : "配信録画",
            url : "https://www.youtube.com/watch?v=ljspl1k_TjQ"
        }
    },

    {
        title : "ビリビリ動画 リアルイベント「BiliBili World 広州」",
        cover : require('@/assets/images/works/201908-1.png'),
        desc : "中国の大規模サブカルイベント「BILIBILI WORLD 2019 広州」が開催されます。イベントではOveridea所属のVTuberの他、ゲストとしてにじさんじやホロライブなど、多数のVTuberが出演します。",
        link : {
            name : "Bilibili World 広州",
            url : "https://bw.bilibili.com/2019/index.html#/guangzhou/"
        }
    },

    {
        title : "株式会社REALITY Factory リアルイベント「夢ノ花園へようこそ♥」",
        cover : require('@/assets/images/works/201904.jpg'),
        desc : "VTuber花園セレナ&ユメノシオリが初めてのリアルイベントを開催！2人のトークと2対1のお話し会(1人60秒まで)で彩る2人の\"夢ノ花園へようこそ♡\"",
        link : {
            name : "livepocket",
            url : "https://t.livepocket.jp/e/yumenohanazono"
        }
    },

    {
        title : "株式会社プロバキャスト リアルイベント 「Vtu\"Bar\"~Sappro Rog-inn Vtuber~」",
        cover : require('@/assets/images/works/201911.jpg'),
        desc : "第2回北海道Vtuberリアルイベント開催！第1回で見つかった課題点を改善し、より迫力のある映像をお届けするため、この回より巨大スクリーンとプロジェクターを導入。参加数も伸びており、初の3人別枠の開催で、イメージドリンクやフードも非常に好評でした！",
        link : {
            name : "V-Tieup",
            url : "https://v-tieup.net/2020/07/11/vtubarvol-1%e3%80%90%e7%b4%a1%e9%9f%b3%e3%82%8c%e3%81%84%e6%a7%98%e3%83%bb%e7%99%bd%e9%9f%b3%e3%81%93%e3%82%86%e3%81%8d%e6%a7%98%e9%bb%92%e9%9f%b3%e3%82%88%e3%82%8b%e6%a7%98%e3%80%91/"
        }
    },

    {
        title : "ビリビリ動画 リアルイベント「BiliBili World 成都」",
        cover : require('@/assets/images/works/201912-1.jpg'),
        desc : "ビリビリワールド2019成都花園serenaも出演することになりました！とてもうれしく思います！また中国のご主人様に会えるなんて(´;ω;｀)",
        link : {
            name : "Bilibili World 成都",
            url : "https://bw.bilibili.com/2019/index.html#/chengdu/"
        }
    },

    {
        title : "ビリビリｘ猫耳FM ラジオドラマ 「非科学定制」主役：美奈子",
        cover : require('@/assets/images/works/202001-1.png'),
        desc : "「非科学定制」というビリビリ公式ボイスドラマ「美奈子」役を演じさせていただきました！",
        link : {
            name : "非科学定制",
            url : "https://www.bilibili.com/bangumi/media/md28226990/"
        }
    },

    {
        title : "Overidea オンラインイベント 「歌え！国境なき日中新年企画！」2019",
        cover : require('@/assets/images/works/202002.jpg'),
        desc : "本放送では総勢３３名にもなるVTuberが世界中にいる皆さんにワンコーラスでメドレー式に歌をお届けします。歌の後には実際に登場して頂きトークをしますが、当日ご多忙な参加者からは素敵なメッセージ動画が届きます。中国と日本、新しいVTuberを知ってみる良い機会ですね！なお、本企画はビリビリ動画とOverideaによる共同主催となります。",
        link : {
            name : "配信録画",
            url : "https://www.youtube.com/watch?v=h-Qh0-t4JqI"
        }
    },

    {
        title : "Overidea オンラインイベント 「歌え！国境なき世界新年企画！」2020",
        cover : require('@/assets/images/works/202002.jpg'),
        desc : "本放送では総勢６０名以上にもなるVTuberが世界中にいる皆さんにワンコーラスでメドレー式に歌をお届けします。歌の後にはVTR出演もしくは生出演で交流！国境を超えたものがここにはある。そう感じて頂ければ幸いです。これを期に、様々なVTuberさんと出会ってみてはいかがでしょう？なお、本企画はビリビリ動画とOverideaによる共同主催となります。",
        link : {
            name : "配信録画",
            url : "https://www.youtube.com/watch?v=ljspl1k_TjQ"
        }
    },
]